import React from "react";
import { graphql } from "gatsby";
import { Grid, Dropdown } from "semantic-ui-react";
import Helmet from "react-helmet";
import Markdown from "markdown-to-jsx";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  Contact,
} from "@fishrmn/fishrmn-components";

import AWS from "aws-sdk";

import axios from "axios";

import Layout from "../components/Layout";
import ContactForm from "../components/ContactForm";
import sendContactFormEmail from "../utils/contactEmail";
import { CONTACT_FORM_FIELDS } from "../utils/consts";

// const sendContactFormEmail = (params) => {
//   const { businessType, businessId, locationId } = params;
//   const url = "https://api.gofisherman.com/";
//   const fullUrl = `${url}businesses/${businessType}s/${businessId}/locations/${locationId}/email/`;
//   // const message = `
//   // What’s your email address: ${params.email}
//   // Who are you trying to reach: ${params.outreach}
//   // Subject: ${params.subject}
//   // Message: ${params.message}
//   // `;

//   const message = `
//   What’s your email address: ${params.email}
//   Who are you trying to reach: ${params.outreach}
//   Message: ${params.message}
//   `;

//   try {
//     let res = axios.post(fullUrl, {
//       contact_name: params.name,
//       contact_email: params.email,
//       contact_message: message,
//       email_subject:
//         "Someone has reached out to you via your website contact form",
//     });
//     return res;
//   } catch (error) {
//     console.log(error);
//   }
// };

function computeDestinationEmail(outreach) {
  let destinationEmail = "";
  switch (outreach) {
    case "careers":
      destinationEmail = "careers@eggnbird.com";
      break;
    case "feedback":
      destinationEmail = "customerservice@eggnbird.com";
      break;
    case "info":
      destinationEmail = "customerservice@eggnbird.com";
      break;
    case "marketing":
      destinationEmail = "marketingdept@yogurt-land.com";
      break;
    case "store":
      destinationEmail = "customerservice@eggnbird.com";
      break;
    case "other":
      destinationEmail = "customerservice@eggnbird.com";
      break;
  }
  return destinationEmail;
}

export default class extends React.PureComponent {
  componentDidMount() {
    AWS.config.update({
      region: "us-east-1",
      credentials: new AWS.Credentials(
        process.env.AWS_ACCESS_KEY_ID,
        process.env.AWS_SECRET_ACCESS_KEY
      ),
    });
  }

  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Contact Us</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={"Contact Us"}
              tagline={""}
              images={[data.heroImages.map(({ url }) => url)[2]]}
              showMap={false}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={true}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"left"}
            centered
          >
            <Grid.Column width={14}>
              <h1>Contact Us</h1>
              <p style={{ textAlign: "center" }}>
                Thank you for contacting us! You can reach a member of our team
                by submitting the form below.
              </p>

              <ContactForm
                buttonText={"Send"}
                centeredHeaders={false}
                className={""}
                fluidButton={false}
                header={null}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={null}
                withContainer={true}
                businessType={"restaurant"}
                businessId={"2592"}
                locationId={2366}
                checkboxLabel="By entering your information, you permit us to reach out to you with future
                communications."
                onSubmit={sendContactFormEmail}
                fields={CONTACT_FORM_FIELDS}
                getDestinationEmail={(fields) =>
                  computeDestinationEmail(fields["outreach"])
                }
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          ctas {
            address {
              link
              text
              internal
            }
          }
          businessName
          phone
          email
          address {
            street
            city
            state
            zip
          }
          hours {
            day
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url
          }
          heroImages {
            url
          }
        }
      }
    }
  }
`;
