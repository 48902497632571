import axios from "axios";

const sendContactFormEmail = (params) => {
  const { businessType, businessId, locationId } = params;
  const url = "https://api.gofisherman.com/";
  const fullUrl = `${url}businesses/${businessType}s/${businessId}/locations/${locationId}/email/`;

  const message = `
  What’s your email address: ${params.email}
  Who are you trying to reach: ${params.outreach}
  Subject: ${params.subject}
  Message: ${params.message}
  `;

  try {
    return axios.post(fullUrl, {
      contact_name: params.name,
      contact_email: params.email,
      contact_message: message,
      contact_opt_in: params.contactOptIn,
      email_subject:
        "Someone has reached out to you via your website contact form",
      destination_email: params.destinationEmail,
    });
  } catch (error) {}
};

export default sendContactFormEmail;
